import React from "react"
import Layout from "../components/layout"
import { LayoutTitle } from "../styles/app.styles"
import { graphql, useStaticQuery } from "gatsby"

import MiscContent from "../layouts/MiscContent"
import WhatOurCustomersSay from "../layouts/WhatOurCustomersSay"
import LetsChatParallax from "../layouts/LetsChatParallax"
import { HomeHero } from "../layouts/HomeHero/home-hero.component"
import BookDemoSection from "../layouts/BookDemoSection"
import ArticlesBanner from "../layouts/ArticlesBanner/articles-banner.component"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      peoplesTalking: file(relativePath: { eq: "peoples-talking.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      parallaxImg: file(relativePath: { eq: "parallax-img.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      circleImage: file(relativePath: { eq: "circle.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }

      homeBackground: file(relativePath: { eq: "home_background.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      semicircleImage: file(relativePath: { eq: "semi-circle.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      homeIphone: file(relativePath: { eq: "home_iphone.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      homePlant: file(relativePath: { eq: "home_plant.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)

  return (
    <Layout
      seo={{
        title: "Layouts - ONLY FOR DEV",
        metaRobotsNoindex: "noindex",
        metaRobotsNofollow: "nofollow",
      }}
    >
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />

      <LayoutTitle>HomeHero</LayoutTitle>
      <HomeHero
        buttonText="Book a Demo"
        title="Own your online store."
        extraTitle="The Future of cannabis"
        paragraph="Your branded app, your online ordering, your data, your everything in one place."
        backgroundImage={staticQuery.homeBackground}
        phoneImage={staticQuery.homeIphone}
        plantImage={staticQuery.homePlant}
      />

      <LayoutTitle>WhatOurCustomersSay</LayoutTitle>
      <WhatOurCustomersSay
        title="Hear from our customers"
        videoImage={staticQuery.peoplesTalking}
        videoUrl={"https://www.youtube.com/embed/XdChXSXXPE4"}
      />

      <LayoutTitle>LetsChatParallax</LayoutTitle>
      <LetsChatParallax
        backgroundImage={staticQuery.parallaxImg}
        letsChatTitle="Let’s chat"
        letsChatContent="We do it all for you, so you can focus on your business."
        demoLink={"/"}
        circleImage={staticQuery.circleImage}
        semicircleImage={staticQuery.semicircleImage}
      />

      <LayoutTitle>BookDemoSection</LayoutTitle>
      <BookDemoSection
        title={"You’re almost finished."}
        description={
          "With a few more details we will have a live demo ready for you"
        }
      />
      <LayoutTitle>Articles Banner</LayoutTitle>
      <ArticlesBanner
        title="Latest news and articles"
        btnReadAll={{
          url: "/",
          name: "Read All",
        }}
      />
    </Layout>
  )
}

export default Layouts
